import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './eigentümer.css';
const Dachfenster = ({Eigentumer, setEigentumer, setPage}) =>{
  const handleChange=(value)=>{
    setEigentumer(value)
    setPage(6)
  } 
  return (
    <Row style={{marginTop:"50px", marginBottom:"20px"}} className='eigen back-button'>
      <Col md={5}>
        <Card onClick={()=>handleChange('Ja')} className='MyButton eigentümer-button' style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", }}>
          <Card.Img variant="top" src="img/elektro-11.png" />
          <Card.Body>
            <Card.Title>Ja</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={5}>
        <Card onClick={()=>handleChange('Nein')} className='MyButton eigentümer-button' style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)"}}>
          <Card.Img variant="top" src="img/elektro-10.png" />
          <Card.Body>
            <Card.Title>Nein</Card.Title>
          </Card.Body>
        </Card>
      </Col>      
    </Row>
  );
}

export default Dachfenster;