import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap stil dosyasını ekleyin
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './personenmobileresponsive.css';
const Personen = ({personen,setPersonen,setPage,price,setPrice}) => {
  const handleChange=(value)=>{
    setPersonen(value)
    setPage(4)
    if (value =='1-2'){
      setPrice('22.000 - 30.000 € Stromkosten')
    } else if(value =='3-4'){
      setPrice('22.000 - 27.000 € Stromkosten')
    }
    else if(value=='5 und mehr'){
      setPrice('28.000 - 33.000 € Stromkosten')
    }
    else{
      setPrice('24.000 - 32.500 € Stromkosten')
    }
  }  
  return (
    <Row style={{marginTop: "50px", marginBottom: "120px"}} className='back-button'>
      <Col md={3}>
        <Card onClick={()=>handleChange('1-2')} className='MyButton personen-mobilebutton' style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", height: "250px", paddingTop:"20px"}}>
          <Card.Img variant="top" src="img/elektro-13.png" />
          <Card.Body>
            <Card.Title style={{paddingTop:"28px"}} className='personen-cardtitle'>1-2</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3}>
        <Card onClick={()=>handleChange('3-4')} className='MyButton personen-mobilebutton' style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", height: "250px", paddingTop:"20px"}}>
          <Card.Img variant="top" src="img/elektro-14.png" />
          <Card.Body>
            <Card.Title style={{paddingTop:"28px"}} className='personen-cardtitle'>3-4</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3}>
        <Card onClick={()=>handleChange('5 und mehr')} className='MyButton personen-mobilebutton' style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", height: "250px", paddingTop:"20px"}}>
          <Card.Img variant="top" src="img/elektro-7.png" />
          <Card.Body>
            <Card.Title style={{paddingTop:"28px"}} className='personen-cardtitle'>5 und mehr</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3}>
        <Card onClick={()=>handleChange('Weiß Nicht')} className='MyButton personen-mobilebutton' style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", height: "250px", paddingTop:"20px"}}>
          <Card.Img variant="top" src="img/elektro-12.png" />
          <Card.Body>
            <Card.Title >Weiß Nicht</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Personen;