import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Icon } from '@iconify/react';
import './postleitzahlresponsive.css';
const Postleitzahl = ({ postleitzahl, setPostleitzahl, setPage, textValue, setTextValue }) => {
  const handleChange = (value) => {
    // Sadece rakamları içerecek şekilde kontrol et
    if (/^\d+$/.test(value) || value === '') {
      setPostleitzahl(value);
    }
  };

  const onClick = () => {
    if (postleitzahl.length === 5) {
      setPage(7);
    } else {
      alert('Postleitzahl muss genau 5 Zeichen lang sein.');
    }
  };

  return (
    <div style={{ marginTop: "100px", marginBottom: "195px" }}>
      <p>Zur Berechnung der Sonneneinstrahlung</p>
      <InputGroup style={{ width: "50%", display: "flex", justifyContent: "center", margin: 'auto' }} hasValidation>
        <Form.Control
          type="text"
          inputMode="numeric"
          required
          maxLength={5}
          value={postleitzahl}
          onChange={(event) => handleChange(event.target.value)}
          isInvalid={postleitzahl.length !== 5}
        />
      </InputGroup>
      {postleitzahl.length === 5 && (
        <Button
          style={{ marginTop: '10px', marginLeft: '330px', background: "white", color: 'black', border: 'none' }}
          onClick={onClick}
          className='MyButton next-button'
          variant="success"
        >
          Abgeben <Icon icon="ant-design:arrow-right-outlined" />
        </Button>
      )}
    </div>
  );
}

export default Postleitzahl;
