import React, { useEffect } from 'react';
import { Icon } from '@iconify/react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap stil dosyasını ekleyin

const LoadingArea = ({ setLoadingArea, setPage })=> {
  useEffect(() => {
    // Belirli bir süre bekledikten sonra bir sonraki sayfaya yönlendir
    const timeout = setTimeout(() => {
      setLoadingArea(false); // setLoadingArea fonksiyonunu kullanarak LoadingArea'yi kapat
      // Diğer sayfaya geçiş işlemleri burada yapılabilir, örneğin:
      setPage(8)
    }, 3000); // 3 saniye bekletme süresi

    // useEffect içinde setTimeout'ı temizleme
    return () => clearTimeout(timeout);
  }, [setLoadingArea]); // setLoadingArea bağımlılık olarak eklenmiştir

  return (
    <div style={{marginTop:"100px"}}>
      <h5>Laden...</h5>
      <Icon icon="meteocons:clear-day-fill" width="200" height="200" rotate={100} hFlip={true} />
      
    </div>
  );
}

export default LoadingArea;