import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './style.css';
import './citymobilresponsive.css';
const City = ({ city, setCity, setPage }) => {
  const handleChange = (value) => {
    setCity(value)
    setPage(1)
  }
  return (
    <Row className='box'>
      <Col md={6} className='left-button'>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Schleswig-Holstein')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", textAlign: "left"
          }}>
            <img
              src="img/sha.png"
              className="rounded-3"
              style={{ width: "35px", marginLeft: '10px', marginRight: "20px" }}
              alt="Schleswig-Holstein"
            />Schleswig-Holstein</Button>
        </div>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Brandenburg')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", textAlign: "left"
          }}>
            <img
              src="img/brd.svg"
              className="rounded-3"
              style={{ width: "35px", marginLeft: '10px', marginRight: "20px" }}
              alt="Brandenburg"
            />
            Brandenburg
          </Button>
        </div>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Bayern')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", textAlign: "left"
          }}>
            <img
              src="img/bayern.svg"
              className="rounded-3"
              style={{ width: "35px", marginLeft: '10px', marginRight: "20px" }}
              alt="Bayern"
            />Bayern</Button>
        </div>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Hessen')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", textAlign: "left"
          }}>
            <img
              src="img/hessen.svg"
              className="rounded-3"
              style={{ width: "35px", marginLeft: '10px', marginRight: "20px" }}
              alt="Hessen"
            />Hessen</Button>
        </div>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Niedersachsen')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", textAlign: "left"
          }}>
            <img
              src="img/ns.svg"
              className="rounded-3"
              style={{ width: "35px", marginLeft: '10px', marginRight: "20px" }}
              alt="Niedersachsen"
            />Niedersachsen</Button>
        </div>
      </Col>
      <Col md={6} className='right-button'>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Baden-Württemberg')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", textAlign: "left"
          }}>
            <img
              src="img/bdw.svg"
              className="rounded-3"
              style={{ width: "35px", marginLeft: '10px', marginRight: "20px" }}
              alt="Baden-Württemberg"
            />Baden-Württemberg</Button>
        </div>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Nordrhein-Westfalen')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarşlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", textAlign: "left"
          }}>
            <img
              src="img/nrw.svg"
              className="rounded-3"
              style={{ width: "35px", marginLeft: '10px', marginRight: "20px" }}
              alt="Nordrhein-Westfalen"
            />Nordrhein-Westfalen</Button>
        </div>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Sachsen')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", textAlign: "left"
          }}>
            <img
              src="img/shn.svg"
              className="rounded-3"
              style={{ width: "35px", marginLeft: '10px', marginRight: "20px" }}
              alt="Sachsen"
            />Sachsen</Button>
        </div>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Rheinland-Pfalz')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", textAlign: "left"
          }}>
            <img
              src="img/rp.svg"
              className="rounded-3"
              style={{ width: "35px", marginLeft: '10px', marginRight: "20px" }}
              alt="Rheinland-Pfalz"
            />Rheinland-Pfalz
          </Button>
        </div>
        <div style={{ margin: '10px' }} className='mobil-buton-box'>
          <Button onClick={() => handleChange('Ich lebe in einem anderen Bundesland')} variant="secondary" className='MyButton mobil-img' size="lg" style={{
            width: "350px", height: "70px", backgroundColor: "white", color: "Black", border: "none", // Kenarlığı sıfırla
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)"
          }}>
            Ich lebe in einem anderen Bundesland
          </Button>
        </div>
      </Col>
    </Row>
  );
}
export default City;