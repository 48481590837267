import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './dachformmobilresponsive.css';
const Dachform = ({dachform, setDachform, setPage})=>{
  const handleChange=(value)=>{
  setDachform(value)
  setPage(2)
}  
  return (
    <Row style={{ marginTop: "50px", marginBottom: "70px" }}>
      <Col md={3}>
        <Card onClick={()=>handleChange('Satteldach')} className='MyButton dachform-button' style={{ width: "200px", height: "300px", paddingTop:"20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)" }}>
          <Card.Img variant="top" src="img/elektro-1.png" style={{paddingBottom: "30px"}} />
          <Card.Body>
            <Card.Title>Satteldach</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3}>
        <Card onClick={()=>handleChange('Flachdach')}  className='MyButton dachform-button' style={{ width: "200px", height: "300px", paddingTop:"20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)" }}>
          <Card.Img variant="top" src="img/elektro-2.png" style={{paddingBottom: "30px"}} />
          <Card.Body>
            <Card.Title >Flachdach</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3}>
        <Card onClick={()=>handleChange('Pultdach')} className='MyButton dachform-button' style={{ width: "200px", height: "300px", paddingTop:"20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)" }}>
          <Card.Img variant="top" src="img/elektro-3.png" style={{paddingBottom: "30px"}} />
          <Card.Body>
            <Card.Title>Pultdach</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3}>
        <Card onClick={()=>handleChange('Anderes')}  className='MyButton dachform-button' style={{ width: "200px", height: "300px", paddingTop:"20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)" }}>
          <Card.Img variant="top" src="img/elektro-12.png" style={{paddingBottom: "50px"}}/>
          <Card.Body>
            <Card.Title className="name">Anderes</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Dachform;