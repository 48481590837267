import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Icon } from '@iconify/react';
import emailjs from '@emailjs/browser';
import './formmobile.css';
const Forms = ({ forms, setForm, city, dachform, stromverbrauchen, dachfenster, personen, eigentumer, postleitzahl, price, setPage }) => {
  const form = useRef();

  const [success, setSuccess] = useState(false)

  const [formState, setFormState] = useState({
    firstName: '',
    vorname: '',
    strasse: '',
    stadt: '',
    postleitzahl: '',
    nummer: '',
    email: '',
    dachform: '',
    dachfenster: '',
    personen: '',
    stromverbrauchen: '',
    eigentumer: ''
  });
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    setLoading(false);
    setPage(0);
  };

  useEffect(() => {
    if (success) {
      handleClick()
    }
  }, [success])

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const result = await emailjs.sendForm('service_2eohevo', 'template_hymy7lh', form.current, 'XyHTjpuK3kE6kY4a3');
      console.log(result.text);
      alert("Nachricht erfolgreich gesendet!");
      setSuccess(true)
    } catch (error) {
      console.error(error.text);
      alert("Nachricht kann nicht gesendet werden!");
    }
  };

  const handleInputChange = (name, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Form ref={form} onSubmit={sendEmail}>
      <h3 className='formlabel'>Gratulation, das Angebot ist in Ihrer Region noch verfügbar! Wir senden Ihnen gerne kostenlose Informationen zu.</h3>
      <h3 style={{ marginTop: "20px", marginBottom: "20px", color: "#150823" }} className='label-form'> {price} <Icon icon="openmoji:solar-energy" /></h3>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          md="6" np 
          className="position-relative form-size"
          
        >
          <Form.Label style={{ marginTop: "20px" }}>Name</Form.Label>
          <Form.Control
            requirede
            name='firstName'
            type="text"
            required 
            onChange={(e) => handleInputChange('firstName', e.target.value)}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md="6" np
          className="position-relative forminput form-size"
        >
          <Form.Label style={{ marginTop: "20px" }}>Vorname</Form.Label>
          <Form.Control
            name='vorname'
            type="text"
            required
            onChange={(e) => handleInputChange('firstName', e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" className='form-size'>
          <Form.Label style={{ marginTop: "20px" }}>Straße</Form.Label>
          <Form.Control
            required
            type="text"
            name='strasse'
            requried
            onChange={(e) => handleInputChange('strasse', e.target.value)}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md="4"
          className='forminput form-size'
        >
          <Form.Label style={{ marginTop: "20px" }}>Stadt</Form.Label>
          <Form.Control
            type="text"
            name='stadt'
            readOnly 
            value={city}
            onChange={(e) => handleInputChange('stadt', e.target.value)}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md="4"
          className='form-size'
        >
          <Form.Label style={{ marginTop: "20px" }}>Postleitzahl</Form.Label>
          <Form.Control
            type="text"
            readOnly
            name='postleitzahl'
            value={postleitzahl}
            onChange={(e) => handleInputChange('postleitzahl', e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" np className="position-relative forminput form-size">
          <Form.Label style={{ marginTop: '20px' }}>Nummer</Form.Label>
          <Form.Control
            name="nummer"
            required
            type="number"
            onChange={(e) => handleInputChange('nummer', e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" np className="position-relative form-size">
          <Form.Label style={{ marginTop: '20px' }}>E-mail</Form.Label>
          <Form.Control
            name="email"
            type="email"
            required className='forminput'
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md="1"
        >
          <Form.Control
            type="hidden"
            name='dachform'
            value={dachform} 
            onChange={(e) => handleInputChange('dachform', e.target.value)}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md="1"
        >
          <Form.Control
            type="hidden"
            name='dachfenster'
            value={dachfenster} 
            onChange={(e) => handleInputChange('dachfenster', e.target.value)}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md="1"
        >
          <Form.Control
            type="hidden"
            name='personen'
            value={personen} 
            onChange={(e) => handleInputChange('personen', e.target.value)}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md="1"
        >
          <Form.Control
            type="hidden"
            name='stromverbrauchen'
            value={stromverbrauchen}
            onChange={(e) => handleInputChange('stromverbrauchen', e.target.value)}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md="1"
        >
          <Form.Control
            type="hidden"
            name='eigentumer'
            value={eigentumer} 
            onChange={(e) => handleInputChange('eigentumer', e.target.value)}
          />
        </Form.Group>
      </Row>
      <Button
        style={{ marginTop: '30px', background: "#150823", background: "white", color: "black", border: "none" }}
        className='MyButton'
        type="submit"
      >Formular Absenden</Button>
    </Form>
  );
  
{success && <div className="alert-container success-alert">Nachricht erfolgreich gesendet!</div>}
// For error alert
{!success && <div className="alert-container error-alert">Nachricht kann nicht gesendet werden!</div>}
};

export default Forms;
