import "./App.css";
import Angebot from "./components/Angebot";

function App() {
  return (
    <div className="App">
      <Angebot />
    </div>
    
  )
}

export default App;