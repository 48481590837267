import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap stil dosyasını ekleyin
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './strommobilresponsive.css';
const  Dachfenster = ({stromverbrauchen, setStromVerbrauchen, setPage}) => {
  const handleChange=(value)=>{
    setStromVerbrauchen(value)
    setPage(5)
  }  
  return (
    <Row style={{marginTop: "50px", marginBottom: "53px"}} className='strom-backbutton'>
      <Col md={4}>
        <Card className='MyButton strom-mobilebutton'onClick={()=>handleChange('Morgens & abends')} style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)"}}>
          <Card.Img variant="top" src="img/elektro-8.png" />
          <Card.Body>
            <Card.Title>Morgens & abends</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card className='MyButton strom-mobilebutton' onClick={()=>handleChange('Verteilt über den Tag')} style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)"}}>
          <Card.Img variant="top" src="img/elektro-9.png" />
          <Card.Body>
            <Card.Title>Verteilt über den Tag</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card className='MyButton strom-mobilebutton tablet' onClick={()=>handleChange('Anderes')} style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", paddingTop: "22px"}}>
          <Card.Img variant="top" src="img/elektro-12.png" style={{marginBottom: "20px"}} />
          <Card.Body>
            <Card.Title className='tabletreponsive'>Anderes</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Dachfenster;