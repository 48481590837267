import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap stil dosyasını ekleyin
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './style.css';
import './dachfenstermobileresponsive.css';
const Dachfenster = ({Dachfenster, setDachfenster, setPage}) => {   
  const handleChange=(value)=>{
    setDachfenster(value)
    setPage(3)
  }  
  return (
    <Row style={{marginTop: "50px", marginBottom: "70px"}}>
      <Col md={4}>
        <Card onClick={()=>handleChange('Ja')} className='MyButton dachfenster-mobilbutton' style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", height: "300px" }}>
          <Card.Img variant="top" src="img/elektro-5.png" style={{paddingBottom: "30px"}} />
          <Card.Body>
            <Card.Title>Ja</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card onClick={()=>handleChange('Nein')} className='MyButton dachfenster-mobilbutton' style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)", height: "300px"}}>
          <Card.Img variant="top" src="img/elektro-6.png" style={{paddingBottom: "30px"}} />
          <Card.Body>
            <Card.Title>Nein</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} className='ex'> 
        <Card onClick={()=>handleChange('Weiß Nicht')} className='MyButton dachfenster-mobilbutton' style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)",paddingTop:"10px" , height: "300px"}}>
          <Card.Img variant="top" src="img/elektro-12.png" style={{paddingBottom: "20px"}}/>
          <Card.Body>
            <Card.Title>Weiß Nicht</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
export default Dachfenster;