import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { Button } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import City from './City';
import Dachform from './Dachform';
import Dachfenster from './Dachfenster';
import Personen from './Personen';
import StromVerbrauchen from './StromVerbrauchen';
import Eigentumer from './Eigentumer';
import Postleitzahl from './Postleitzahl';
import LoadingArea from './LoadingArea';
import Form from './Form';
import './style.css';
import './angebotmobileresponsive.css';

function Angebot() {
  const [page, setPage] = useState(0);
  const [city, setCity] = useState('');
  const [dachform, setDachform] = useState('');
  const [dachfenster, setDachfenster] = useState('');
  const [personen, setPersonen] = useState('');
  const [stromverbrauchen, setStromVerbrauchen] = useState('');
  const [eigentumer, setEigentumer] = useState('');
  const [postleitzahl, setPostleitzahl] = useState('');
  const [loadingarea, setLoadingArea] = useState('');
  const [Forms, setForms] = useState('');
  const [textValue, setTextValue] = useState('');
  const [price, setPrice] = useState('')


  const FormTitles = [
    "In welchem Bundesland leben Sie?",
    "Welche Dachform hat Ihr Haus?",
    "Besitzt Ihr Haus Gauben oder Dachfenster?",
    "Wie viele Personen leben in Ihrem Haushalt?",
    "Wann verbrauchen Sie Ihren Strom?",
    "Sind Sie Eigentumer des Hauses?",
    "Wie lautet Ihre Postleitzahl?",
    "Wir prüfen, ob das Angebot noch in Ihrer Region verfügbar ist.",
    "Gratulation, das Angebot ist in Ihrer Region noch verfügbar! Wir senden Ihnen gerne kostenlose Informationen zu."
  ];

  const PageDisplay = () => {
    if (page === 0) {
      return <City city={city} setCity={setCity} setPage={setPage} />;
    } else if (page === 1) {
      return <Dachform dachform={dachform} setDachform={setDachform} setPage={setPage} />;
    } else if (page === 2) {
      return <Dachfenster dachfenster={dachfenster} setDachfenster={setDachfenster} setPage={setPage} />;
    } else if (page === 3) {
      return <Personen setPrice={setPrice} personen={personen} setPersonen={setPersonen} setPage={setPage} />;
    } else if (page === 4) {
      return <StromVerbrauchen stromverbrauchen={stromverbrauchen} setStromVerbrauchen={setStromVerbrauchen} setPage={setPage} />;
    } else if (page === 5) {
      return <Eigentumer eigentumer={eigentumer} setEigentumer={setEigentumer} setPage={setPage} />;
    } else if (page === 6) {
      return <Postleitzahl textValue={textValue} setTextValue={setTextValue} postleitzahl={postleitzahl} setPostleitzahl={setPostleitzahl} setPage={setPage} />;
    } else if (page === 7) {
      return <LoadingArea loadingarea={loadingarea} setLoadingArea={setLoadingArea} setPage={setPage} />;
    } else {
      return <Form city={city} price={price} dachform={dachform} stromverbrauchen={stromverbrauchen} eigentumer={eigentumer} dachfenster={dachfenster} postleitzahl={postleitzahl} personen={personen} Forms={Forms} setForm={setForms} setPage={setPage} />;
    }
  };

  const isBackButtonVisible = () => {
    return page !== 7 && page !== 8 && page !== 0;
  };
  const isFormTitleVisible = () => {
    return page !== 8;
  };
  return (
    <div className='Angebot'>
      <div className='ProgressBar'></div>
      <div className='Form-container'>
        <div className='body body-mobil'>
          <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div
              style={{
                width: '70%',
                height: '600px',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)', // Gölgelendirme
                background: '#a8b1c3 '

              }}
              className="card card-mobile"
            >
              <div className="card-body">
                <h6 style={{ textAlign: 'left', marginLeft: '35px' }}>Kostenloser Solarstrom-Check in einer Minute.</h6>
                <h3 className='baslik' style={{ marginBottom: '20px' }}>{isFormTitleVisible() && FormTitles[page]}</h3>
                <p className="card-text">
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                  </div>
                  <div>{PageDisplay()}</div>
                  <div style={{ marginTop: '10px' }} className="footer">
                    <Button
                      className='MyButton'
                      hidden={!isBackButtonVisible()}
                      variant="outline-secondary"
                      size="lg"
                      style={{ marginRight: "600px", border: "none", fontSize: "15px", color: "white" }}
                      disabled={page === 0}
                      onClick={() => { setPage((currPage) => currPage - 1); }}
                    >
                      <Icon style={{ marginRight: "10px", fontSize: "15px", color: "white" }} icon="fluent-mdl2:chrome-back" />
                      Zurück
                    </Button>
                  </div>
                </p>
              </div>
            </div>
          </Container>
        </div>
        <footer className="footerbanner footer-mobil">
          <div className="containermn">
              <p className='footerp'>Urheberrecht © 2023 EH Elektro, Alle Rechte vorbehalten. Unterstützt von <a href='https://novitasgroups.com'>Novitas Premium Solutions</a></p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Angebot;
